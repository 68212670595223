// utilities.sass
    
// Generic Styling
*
    font-family: 'Nunito Sans'

// Transitions & Effects
.fade-in
    transition: opacity 1s
    opacity: 1

.fade-out 
    transition: opacity .4s
    opacity: 0

.top-nav
    position: fixed
    z-index: 110
    padding: 24px
    color: #fff
    opacity: .85

.display
    padding: 2%
    bottom: 0
    position: absolute
    border-radius: 5px
    font-size: 1em
    color: #ffffff
    text-shadow: 2px 2px 6px rgba(0, 0, 0, .2)


.display
    margin: auto
    position: fixed
    top: 50%
    left: 50%
    transition: opacity 1s
    transform: translate(-50%, -50%)
    text-align: center
    z-index: 99
    
@media (max-width: 1200px)
    .display
        width: 85%

.time-display
    .clock
        font-size: 160px
        line-height: 1
        font-weight: 300
    .date
        font-size: 20px
    @media (max-width: 1200px)
        .clock
            font-size: 96px
            line-height: 1
            font-weight: 300
        .date
            font-size: 16px


@media (max-width: 600px)
    .buttonLabel
        display: none
// Misc
.ambient-item
    border: 1px solid transparent
    padding: 10px
    cursor: pointer
    transition: border-color 0.3s

    &:hover
        border-color: blue

    &.ambient-selected
        border-color: darkblue

.active-control
    display: block
