
// components.sass
    
.background-video
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: -1
    object-fit: cover


// Navigation
.nav
    display: flex
    justify-content: center
    align-items: center
    padding: 32px 56px
    background: linear-gradient(180deg, rgba(16, 14, 20, 0) 100% rgba(11, 11, 18, 0.6) 0% )
    
    transition: opacity 1s ease-in-out
    opacity: 1
    position: absolute
    left: 0
    right: 0
    bottom: 0
    margin: auto
    z-index: 103
    .hidden 
        opacity: 0
        pointer-events: none

    .logo
        width: 100px
        height: auto
        color: #fff 

    .menu-items
        display: flex
        gap: 4px
        background: rgba(255,255,255,0.6)
        padding: 12px 12px
        border-radius: 64px
        backdrop-filter: blur(16px)
        z-index: 101
        width: fit-content
        transition: all ease .6s
        
        button
            background: none
            border: none
            cursor: pointer
            transition: opacity 0.3s


        .menu-btn
            background-color: transparent
            border-radius: 32px
            border: none
            padding: 18px 40px
            display: flex
            align-items: center
            color: #38363fde
            cursor: pointer
            transition: background-color 0.3s ease
            gap: 12px
            font-size: 1em
            font-weight: 600
            transition: all ease .2s

            &:hover
                background-color: rgba(255, 255, 255, 0.4)
                transform: scale(1.04)
                color: #1f1f23ee
            &:active
                transform: scale(0.96)
                background-color: rgba(255, 255, 255, 0.6)

            &.active
                background-color: white
                color: black
    .top-right-nav
        position: fixed
        right: 24px
        top: 24px
        display: flex
        gap: 4px
        .fullscreen
            background-color: rgba(0, 0, 0, 0.2)
            border-radius: 32px
            border: none
            padding: 14px 32px
            display: flex
            align-items: center
            color: white
            cursor: pointer
            transition: background-color 0.3s ease
            gap: 12px
            font-size: 1em
            font-weight: 500
            
            &:hover
                background-color: rgba(255, 255, 255, 0.2)

            &.active
                background-color: white
                color: black
        .subscribe-btn
            background-color: rgb(255, 255, 255)
            border-radius: 32px
            border: none
            padding: 14px 32px
            display: flex
            align-items: center
            color: #3b3941
            cursor: pointer
            transition: background-color 0.3s ease
            gap: 12px
            font-size: 1em
            font-weight: 500
            
            &:hover
                background-color: rgba(255, 255, 255, 0.2)

            &.active
                background-color: white
                color: black         

// Control Container
.control-container
    background: #30303625
    height: 100%
    width: 100%
    position: fixed
    pointer-events:none
    backdrop-filter: blur(24px)
    z-index: 100    
.wrapper
    width: fit-content
    min-width: 420px
    max-width: 90%
    margin: auto
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background: rgba(255, 255, 255, 0.7)
    padding: 40px
    border-radius: 24px
    color: #35343a
    transition: opacity 1s ease-in-out
    backdrop-filter: blur(16px)
    box-shadow: 0 12px 32px -14px rgba(23, 22, 24, 0.2)
    z-index: 100
    box-sizing: border-box

    @media (max-width: 800px)
        min-width: auto
        width: 100%
        padding: 20px
        max-height: 60%
        

    

    .music-player
        display: flex
        flex-direction: column
        align-items: center
        margin-bottom: 20px

        .playlist-selector
            select
                font-size: 16px
                padding: 6px
                

        .player-controls
            display: flex
            margin-top: 10px

            button
                font-size: 16px
                padding: 6px 10px
                margin: 0 5px
                cursor: pointer

        

    .controls-header
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        padding: 10px 0
        
        h3
            margin: 0
            font-size: 1.5em

        input[type="range"]
            width: 160px

        @media (max-width: 800px)
            flex-wrap: 1
            flex-direction: column
            gap: 12px

    .ambient-thumbnails
        display: grid
        grid-template-rows: 1fr 1fr
        grid-template-columns: 1fr 1fr 1fr 1fr
        justify-content: space-around
        width: 100%
        margin-top: 10px
        gap: 12px
        @media (max-width: 600px)
            display: grid
            grid-template-rows: 1fr 1fr
            grid-template-columns: 1fr 1fr
        div
            cursor: pointer
            transition: all 0.3s ease
            height: fit-content
            display: flex
            border-radius: 5px
            overflow: hidden
            outline: 2px solid #3498db00
            @media (max-width: 600px)
                max-height: 160px
            @media (max-width: 400px)
                max-height: 120px    


            img
                border-radius: 5px
                object-fit: cover
                opacity: 0.9
                transition: all ease .2s

            &:hover
                transform: scale(1.04)
                outline: 2px solid #ffffff
                box-shadow: 0 6px 24px -12px #39373c
                img
                    opacity: 1
            &:active
                transform: scale(0.96)
                img
                    opacity: .8
            &.active
                transition: all ease .3s
                outline: 4px solid #ffffff
                box-shadow: 0 6px 24px -12px #39373c



.entry-page
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center
    height: 100vh
    background: url('calmood_bg.jpg') no-repeat center center fixed
    background-size: cover

    h1
        font-size: 3rem
        color: #fff
        margin-bottom: 1rem

    p
        font-size: 1.2rem
        color: #fff
        margin-bottom: 2rem
        text-align: center

    button
        background-color: rgb(255, 255, 255)
        border-radius: 32px
        border: none
        padding: 14px 32px
        display: flex
        align-items: center
        color: #343338
        cursor: pointer
        transition: background-color 0.3s ease, transform 0.3s ease
        gap: 12px
        font-size: 1em
        font-weight: 500

        &:hover
            background-color: rgb(255, 255, 255)
            transform: scale(1.05)

        &:active
            transform: scale(0.95)
.volume-controls
    display: flex
    align-items: center
    gap: 4px

.controls
    .play-controls
        display: flex
        margin: 0 32px
        button
            background-color: transparent
            border-radius: 50%
            border: none
            padding: 8px
            width: 40px
            height: 40px
            display: flex
            align-items: center
            justify-content: center
            transition: transform 0.3s ease, background-color 0.3s ease
            cursor: pointer
            svg
                fill: #2a282d
                width: 24px
                height: 24px

            &:hover
                background-color: rgba(255, 255, 255, 0.66)
                transform: scale(1.05)


            &:active
                transform: scale(0.95)

.options
    display: flex
    gap: 10px
    margin-top: 20px
    width: 100%

    button
        flex: 1
        background-color: rgba(255, 255, 255, 0.2)
        border-radius: 12px
        border: none
        padding: 24px 20px
        color: #37343c
        font-size: 1em
        letter-spacing: 2%
        font-weight: 500
        cursor: pointer
        transition: all 0.3s ease
        outline: 2px solid #ffffff00


        &:hover
            transform: scale(1.04)
            outline: 2px solid #ffffff
            box-shadow: 0 6px 24px -12px #39373c
            background-color: rgba(255, 255, 255, 0.4)

        &:active
            transform: scale(0.96)
            img
                opacity: .8
        &.active
            transition: all ease .3s
            outline: 2px solid #ffffff
            background-color: rgba(255, 255, 255, 1)
            box-shadow: 0 6px 24px -12px #39373c


    
.more
    div
        display: flex
        align-items: center
        gap: 12px
        margin-bottom: 12px

        input[type="checkbox"]
            width: 18px
            height: 18px
            cursor: pointer

        label
            font-size: 1.2em
            color: #252427
            cursor: pointer
        &:hover
            label
                opacity: .8
.entry-page
    opacity: 0
    transition: opacity 1s ease-in-out
.main-app
    opacity: 0
    transition: opacity 1s ease-in-out


.control-container
    transition: opacity 0.5s ease-in-out

    &.fade-in
        opacity: 1

    &.initiating-fade-out
        opacity: 0

    > * // This targets direct children of .control-container
        transition: opacity 0.5s ease-in-out 0.5s // This delay ensures that children start fading in after parent's fade-in is done.
        opacity: 1

        .control-container.initiating-fade-out &
            opacity: 0
.loader
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding: 20px
    border-radius: 5px
    font-size: 18px
    z-index: 10
    color: #5b5c62 !important
.video-loading-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.6)
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000

.loader
    color: white
    font-size: 24px

.background-video.next-video
    position: absolute
    top: 0
    opacity: 1
    transition: opacity 0.5s
    &.loaded
        opacity: 1
