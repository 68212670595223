// Import font
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500&display=swap')

// Global Styles
*
    margin: 0
    font-family: 'Nunito Sans'

// Typography
p, h1, h2, h3, h4, h5, h6
    margin: 0

h2
    font-size: 8em
    font-weight: 300
    line-height: 1

h3
    font-weight: 300
    font-size: 3em
    line-height: 1.2
    margin-bottom: 20px
    @media (max-width: 800px)
        font-size: 2.4em

h4
    font-weight: 500
    font-size: 1.5em
    line-height: 1.2

h5
    font-weight: 400
    font-size: 1.4em

body
    background: #201f24